import React from "react";
import { Link } from "react-router-dom";

import KjmLogo from "../../assets/kjmlogo.png";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__inner">
        <div className="Footer__links">
          <Link to="/products">Products</Link>
          <Link to="/services">Services</Link>
          <Link to="/farming-manufacturing">Farming &amp; Manufacturing</Link>
        </div>
        <div className="Footer__info">
          <div>
            <p>KJM Trading</p>
            <p>info@kjmtradingltd.com</p>
          </div>
          <div>
            <p style={{ marginTop: "8px" }}>Socials:</p>
            <a
              href="https://twitter.com/kjmfoundationgh?s=11&t=90MuWpIvkIhveNBsMZoJeA"
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>

        <div className="Footer__designedBy">
          <div className="Footer__image-box">
            <img src={KjmLogo} alt="kjm" className="Footer__image" />
          </div>
          {/* <p>
            Developed by{" "}
            <a
              href="https://xoladigital.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Xola Digital
            </a>
          </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
